.c-figure {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;

    &.type5{
      border-radius: 1rem;
    }
  }

  &.-large{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    min-height: 900px;
  }

  &.-no-radius{
    img{
      border-radius: inherit;
    }
  }

  &.-intro{
    position: relative;
    bottom: 9vw;
    z-index: -1;
    max-height: 490px;
  }
}
