.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba($color: $grey, $alpha: 0.15);

  .c-header_logo{
    img{
      max-height: rem(50px);
    }
  }

  .c-header_nav{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .c-header_nav_don{
      @media (max-width: 1500px){
        display: none;
      }
    }

    .c-header_nav_menu{
      @media (max-width: 1200px){
        display: none;
      }
    }
  }

  .c-header_nav_burger {
    width: 3rem;
    height: 3rem;
    border: 2px solid $grey;
    border-radius: 50%;

    .c-header_nav_cross{
      display: none;
    }

    &.is-active {
      .c-header_nav-icon {
        span {
          &:nth-child(1) {
            top: rem(25px);
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            top: rem(25px);
            transform: rotate(-135deg);
          }
        }
      }

      .c-header_nav_icon_burger{
        display: none;
      }

      .c-header_nav_cross{
        display: inherit;
      }
      &:hover{
        transform: inherit;
      }
    }
    &:hover:not(.is-active) {
      .c-header_nav-icon {
        span {
          &:nth-child(1) {
            top: rem(20px);
          }
          &:nth-child(2) {
            top: rem(26px);
          }
        }
      }
    }
  }

  @media (max-width: 900px){
    justify-content: space-between;

    .-grow1{
      .c-heading{
        display: none;
      }
    }
  }
}
