.b-cta{
    min-height: 900px;
    &.-large{
        min-height: 730px;
    }

    .b-cta_header{
        position: relative;
    }

    .b-cta_deco{
        padding: 5rem 0;
        position: relative;
        overflow: hidden;
        z-index: 0;

        &::after{
            content: '';
            width: 300vw;
            height: 300vw;
            border-radius: 50%;
            background-color: $second;
            position: absolute;
            bottom: 0;
            left: -100vw;
            z-index: -1;
        }
    }

    .b-cta_btn{
        position: absolute;
        bottom: -25px;
        left: 35%;
        right: 35%;
        display: flex;
        justify-content: center;
    }

    .b-cta_lien_externe{
        span{
            margin-left: 0.5rem;
            
            svg{
                path{
                    fill: $grey;
                }
            }
        }
    }
}
