.b-tiroirs {
    
    @media (max-width: $from-medium){
        .c-accordion{
            .c-accordion_main{
                .c-accordion_main_wrap{
                    flex-direction: column;

                    .c-accordion-border{
                        border-right: inherit;
                    }
                }
            }
        }
    }
}