.c-button {
  padding: 1rem;
  margin-left: -1rem;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
  
  .c-button_label {
    z-index: 1;
    position: relative;

    svg{
      z-index: 1;
    }
    .svg{
      transition: 0.3s ease-out;
    }

    &.-red{
      svg{
        path, line{
          fill: $red;
          stroke: $red;
        }
      }
    }

    &.-round{
      height: 3rem;
      width: 3rem;
    }
  }
  .icon {
    margin-left: 1rem;
    svg {
      max-width: 50px;
    }
  }

  &.-icon{
    width: fit-content;
    padding: 5px;
    margin-right: -5px;
  }

  &.-text{
    margin-left: inherit;
    width: 100%;
    border-radius: inherit;
    padding: 0;

    span{
      svg{
        path, line{
          fill: $white;
          stroke: $white;
        }
      }
    }

    &::after{
      background-color: $primary;
    }
  }

  &.-primary{
    color: $grey;
    background-color: $primary;
    min-width: 200px;

    &::after{
      background-color: $primary;
      opacity: 0.9;
    }

    &.-fleche{
      text-align: center;
      padding: 1rem 1.5rem;
      
      span{
        font-weight: 500;
      }
      
      svg{
        z-index: 1;
        position: relative;
        margin-left: 0.5rem;
        path, line{
          fill: $red;
          stroke: $red;
        }
      }
    }
  }

  &.-red{
    &::after{
      background-color: $primary;
    }
  }

  &.-white {
    .c-button_label {

    }
  }

  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: center;
    transform: scaleX(0);
    background: $second;
    filter: brightness(0.92);
    transition: 0.3s ease-out;
    z-index: 0;
  }

  &:hover{
    .c-button_label{
      .svg{
        transform-origin: center;
        transform: scaleX(125%);
      }
    }

    &::after{
      transform: scaleX(100%);
    }
  }

  // @include u-hocus {
  //   background-color: $primary;
  //   color: $white;
  //   .c-button_label {
  //   }
  // }
}

.c-button-full {
  padding: rem(15px) rem(20px);
  background-color: $primary;
  color: $white;
  transition: 0.3s $easing;
  font-weight: $medium;
  display: flex;
  align-items: center;
  .icon {
    margin-right: 1rem;
  }
  @include u-hocus {
    background-color: $second;
  }
  &.-addtocart {
    border-radius: 0px 10px 10px 0px;
  }
}

.c-button-round {
  display: grid;
  overflow: hidden;
  border-radius: 50%;
  max-width: 500px;
  .c-button-round_round {
    grid-column: 1;
    grid-row: 1;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: $grey-light;
    transform-origin: center center;
    transform: scale(0.8);
    transition: 0.3s $easing;
  }
  .c-button-round_btn {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s $easing;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  &:hover {
    .c-button-round_round {
      transform: scale(1);
      background-color: $grey;
    }
    .u-c-primary {
      color: $second;
    }
  }
}
