.b-video {
    .c-modal_wrap {
        display: none;
    }
    .b-video_wrap {
        position: relative;
        border-radius: rem(5px);
        overflow: hidden;
    }
    .b-video_content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: rem(435px);
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $primary;
            opacity: 0.19;
        }
    }

    /* The outer-div to move the elements */
    .box-video{
        position: relative;
        width: 100%;
        margin:0 auto 20px auto;
        cursor: pointer;
        overflow: hidden;
        border-radius: 2rem;
    }
        
    /* Set Cover aka Background-Image */
    .box-video .bg-video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 2;
        overflow: hidden;

        .bg-video_img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s ease-out;
        }

        &:hover{
            .bt-play{
                &::after{
                    transform: scaleX(100%);
                }
            }

            img{
                transform: scale(1.15);
            }
        }
    }
    
    /* Add light shade to make play button visible*/
    .bg-video::after{ 
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
    
    
    /* The Play-Button using CSS-Only */
    .box-video .bt-play {
        padding: 0.8rem 3rem;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        transition: 0.3s ease-out;
        background: $primary;

        div{
            z-index: 2;
        }

        &::after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(255, 202, 110, 0.9);
            transform: scaleX(0%);
            transform-origin: center;
            position: absolute;
            left: 0;
            top: 0;
            transition: 0.3s ease-out;
            z-index: 1;
        }
    }
    
    /* When Class added the Cover gets hidden... */
    .box-video.open .bg-video{
        visibility: hidden;
        opacity: 0;
        
        -webkit-transition: all .6s .8s;
        transition: all .6s .8s;  
    }
    
    /* and iframe shows up */
    .box-video.open .video-container{
        opacity: 1;
        -webkit-transition: all .6s .8s;
        transition: all .6s .8s;
    }
    
    /* Giving the div ratio of 16:9 with padding */
    .video-container {
        position: relative;
        width: 100%;
        height: 0;
        margin: 0;
        z-index: 1;
        padding-bottom: 56.27198%;
    }
    
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
