.c-footer{
    min-height: 670px;
    background-color: $grey-footer;

    p, span, a, address{
        color: $white-footer;
        font-size: $font-size-h6;
    }

    .c-footer_bottom_links{
        border-bottom: 2px solid $white-footer;
        opacity: 0.5;
        .c-footer_link{
            &:not(:last-of-type){
                padding-right: 4rem;
            }
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: $semibold;
            line-height: 3rem;
            color: $white-footer;
        }

        @media(max-width: 550px){
            display: flex;
            flex-direction: column;

            .c-footer_link{
                padding-right: inherit !important;
            }
        }
    }

    .logo{
        width: rem(200px);
    }

    .c-footer_bottom{
        background-color: darken($grey-footer, 5%);

        a, span{
            font-size: $font-size;
        }

        .o-layout_item{
            padding-right: 1rem;
        }

        @media (max-width: $from-small){
            .o-container{
                .o-layout{
                    justify-content: center;
                }
            }
        }
    }

    @media (max-width: 547px){
        .c-footer_content{
            .u-padding-top{
                justify-content: center !important;
            }
        }
    }
        

    @media(max-width: 900px){
        .o-container{
            flex-direction: column;
            text-align: center;

            .c-footer_content, .c-footer_contact{
                width: 100% !important;
            }
        }
    }
}
