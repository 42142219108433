// ==========================================================================
// Objects / Layout
// ==========================================================================

//
// Grid-like layout system.
//
// The layout object provides us with a column-style layout system. This file
// contains the basic structural elements, but classes should be complemented
// with width utilities, for example:
//
// @example
//   <div class="o-layout">
//     <div class="o-layout_item  u-1/1  u-1/3@medium">
//     </div>
//     <div class="o-layout_item  u-1/2  u-1/3@medium">
//     </div>
//     <div class="o-layout_item  u-1/2  u-1/3@medium">
//     </div>
//   </div>
//
// We can also manipulate entire layout systems by adding a series of modifiers
// to the `.o-layout` block. For example:
//
// @example
//   <div class="o-layout  -reverse">
//
// This will reverse the displayed order of the system so that it runs in the
// opposite order to our source, effectively flipping the system over.
//
// @example
//   <div class="o-layout  -[right|center]">
//
// This will cause the system to fill up from either the centre or the right
// hand side. Default behaviour is to fill up the layout system from the left.
//
// @requires tools/layout
// @link https://github.com/inuitcss/inuitcss/blob/0420ba8/objects/_objects.layout.scss
//

.o-layout {

    // Gutter modifiers
    &.-gutter {
        margin-left: rem(-$unit);
    }

    &.-gutter-small {
        margin-left: rem(-$unit-small);
    }

    &.-gutter-large {
        margin-left: rem(-$unit-large);
    }

    // Horizontal aligment modifiers
    &.-center {
        text-align: center;
    }

    &.-right {
        text-align: right;
    }

    &.-reverse {
        direction: rtl;

        &.-flex {
            flex-direction: row-reverse;
        }
    }

    &.-flex {
        display: flex;

        &.-j-right{
            justify-content: flex-end;
        }

        &.-j-center{
            justify-content: center;
        }

        &.-j-between{
            justify-content: space-between;
        }

        &.-top {
            align-items: flex-start;
        }
        &.-middle {
            align-items: center;
        }
        &.-bottom {
            align-items: flex-end;
        }
        &.-wrap {
            flex-wrap: wrap;
        }
        &.-column{
            flex-direction: column;
        }
        &.-grow1{
            flex-grow: 1;
        }
        .order-1 {
            order: 1;
        }
        .order-2 {
            order: 2;
        }
        @media (max-width: $to-small) {
            .order-1\@to-small {
              order: 1;
            }
            .order-2\@to-small {
              order: 2;
            }
        }
    }
    &.-stretch {
        align-items: stretch;
    }
}

.o-layout_item {
    @include o-layout_item;

    font-size: rem(16px);

    // Gutter modifiers
    .o-layout.-gutter > & {
        padding-left: rem($unit);
    }

    .o-layout.-gutter-small > & {
        padding-left: rem($unit-small);
    }

    .o-layout.-gutter-large > & {
        padding-left: rem($unit-large);
    }

    // Vertical alignment modifiers
    .o-layout.-middle > & {
        vertical-align: middle;
    }

    .o-layout.-bottom > & {
        vertical-align: bottom;
    }

    // Horizontal aligment modifiers
    .o-layout.-center > &,
    .o-layout.-right > &,
    .o-layout.-reverse > & {
        text-align: left;
    }

    .o-layout.-reverse > & {
        direction: ltr;
    }

    &.order-1 {
      order: 1;
    }
    &.order-2 {
      order: 2;
    }
    &.order-3 {
      order: 3;
    }
}
