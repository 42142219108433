// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%; /* [2] */
    color: $color;
    font-family: $font-family;
    font-weight: $light;
    line-height: $line-height; /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    background-color: white !important;
    transition: background-color $speed $easing;
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

main {
  padding-top: rem($header-height);
}

.app {
  opacity: 0;
  transition: opacity 0.6s $easing;
}

.is-animated .app {
  opacity: 1!important;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $grey;
    transition: 0.3s $easing;

    @include u-hocus {
        color: $link-hover-color;
    }
}

.e-page_intro{
    position: relative;

    .e-page_intro_deco{
        position: relative;
        overflow: hidden;
        z-index: 0;
        padding: 5rem 0.5rem;
        
        &::after{
            content: '';
            width: 300vw;
            height: 300vw;
            border-radius: 50%;
            background: $second;
            position: absolute;
            bottom: 0;
            left: -100vw;
            z-index: -1;
        }

        @media (max-width: $from-tiny){
            padding: 5rem 0.5rem;
        }
    }

    .e-page_intro_bd{
        display: flex;
        justify-content: center;
        align-items: center;

        a, span{
            text-transform: uppercase;
            color: $grey;
        }

        .pipe{
            margin-left: 1rem;
            display: flex;
            align-items: center;

            &::before{
                content: '';
                width: 1.5rem;
                height: 2px;
                background-color: $grey;
                margin-right: 1rem;
            }
        }

        @media (max-width: 500px){
            display: none;
        }
    }

    .e-page_intro_search{
        padding: 1rem 1.5rem;
        border: 2px solid $grey;
        border-radius: 3rem;
        max-width: 500px;
        margin: 4rem auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .e-page_intro_separator{
        width: 1.5rem;
        height: 2px !important;
        background-color: $grey;
        margin: 0  0 0 1rem;
        border-radius: inherit !important;
    }

    .e-page_intro_date{
        span{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey;
            margin-left: 1rem;

            svg{
                height: 1rem;
            }
        }
    }
}
