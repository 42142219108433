// .b-organigramme{
//     overflow: hidden;
//     .badge{
//         border-bottom: 3px solid $grey;
//     }

//     .o-layout_item{
//         @media (min-width: $from-medium){
//             min-width: 33.33%;
//         }

//         @media (max-width: $from-small){
//             min-width: 50%;
//         }
//     }

//     .level-wrapper{
//         overflow: hidden;
//     }

//     .b-organigramme_title_level{
//         flex-grow: 1;
//     }

//     .wrapper{
//         overflow: hidden;
//     }

// }
.c-slider_nav_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
}
