// ==========================================================================
// Base / Fonts
// ==========================================================================

// Regular

@include font-face(
    $font-hind,
    "fonts/HindMadurai-Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 400;
}

@include font-face(
    $font-exo2,
    "fonts/Exo2-Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 400;
}

//Bold

@include font-face(
    $font-hind,
    "fonts/HindMadurai-Bold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 700;
}

@include font-face(
    $font-exo2,
    "fonts/Exo2-Bold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 700;
}

// SemiBold

@include font-face(
    $font-hind,
    "fonts/HindMadurai-SemiBold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 600;
}

@include font-face(
    $font-exo2,
    "fonts/Exo2-SemiBold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 600;
}
