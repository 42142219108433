.b-fichiers {
    .b-fichiers_wrap {
        display: grid;
        grid-gap: rem($unit-tiny);

        a{
            padding-bottom: 1rem;
            border-bottom: 1px solid $grey;
        }
    }

    .b-fichiers_wrapper{

        @media(max-width: 700px){
            flex-direction: column;
        }
    }
}
