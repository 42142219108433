.c-accordion_item {
  border: 2px solid $grey;
  border-radius: 1.5rem;
  padding: 2rem;
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .c-heading {
      padding-right: 50px;

      @media (max-width: $from-tiny){
        padding-right: 25px;
      }
    }
  }
  .c-accordion_plus {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    margin-left: 0.66667rem;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 3px solid $red;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -1.5px;
    }
    &::after {
      border-left: 3px solid $red;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1.5px;
    }
    .icon {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        max-width: 50px;
      }
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
    
    .c-accordion-border{
      border-right: 1px solid $grey;

        ul{
          li{
            display: flex;
            &:not(:last-of-type){
              padding-bottom: 0.2rem;
            }
            padding-left: 2rem;
          &::before{
            content: '';
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: $second;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }

  &.is-open {
    .c-accordion_header{
      padding-bottom: 2rem;
      border-bottom: 1px solid $grey;
    }
    .c-accordion_main {
      height: auto;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }

    header{
      .u-c-grey{
        color: $primary;
      }
    }
  }

  header{
    .u-c-grey{
      transition: 0.3s ease-out;
    }
  }

  &:hover{
    header{
      .u-c-grey{
        color: $primary;
      }
    }
  }
}
