.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-white {
    color: $white;
}
.u-c-white2 {
    color: $white-footer2;
}
.u-c-grey{
  color: $grey;
}
.u-c-red{
  color: $red;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-red{
  background-color: $red;
}
.u-bg-black {
    background-color: $black;
}
.u-bg-white {
    background-color: $white;
}
