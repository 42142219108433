.c-nav {
  position: fixed;
  top: rem($header_height);
  left: 0;
  right: 0;
  z-index: 9;
  //visibility: hidden;
  transform: translateY(calc(-100% - #{rem($header_height)}));
  transition: transform 0.3s $easing;
  height: calc(100vh - #{rem($header_height)});
  display: flex;
  justify-content: flex-end;
  .c-nav_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }
  .c-nav_wrap {
    background-color: $second;
    overflow-x: auto;

    .c-nav_primary_container{
      position: relative;
      @media(max-width: 1600px){
        .o-layout{
          display: none;
        }

      @media(max-width: 350px){
        padding-left: 5.5rem !important;
      }

        .c-nav_primary{
          &::before{
            display: none;
          }
        }
      }
    }
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-nav_primary {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateX(-200px);
    transition: 0.3s $easing;
    transition-delay: 0.3s;
    color: $white;
    .is-active {
      color: $red;
    }

    .c-nav_primary_sublink{
      transform: translateY(10rem);
      transform-origin: top;
      transition: transform 0.3s ease-out;
      height: 0;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a{
        cursor: pointer;
        &:not(:last-of-type){
          margin-bottom: 1rem;
        }
        
        &:hover{
          color: $red;
        }
      }
    }

    .c-nav_primary_link{
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;

      .c-nav_primary_plus{
        width: 1.2rem;
        height: 4px;
        background: $red;
        position: relative;

        &::after{
          content: '';
          height: 1.2rem;
          width: 4px;
          background-color: $red;
          position: absolute;
          left: calc(50% - 2px);
          top: calc(-0.6rem + 2px);
          transition: 0.3s ease-out;
          transform-origin: center;
        }
      }
      &:hover{
        color: $red;
      }
    }

    .h-clicked{
      overflow: inherit;
      .c-nav_primary_sublink{
        transform: translateY(0rem);
        opacity: 1;
        height: inherit;
        margin-top: 2rem;
      }

      .c-nav_primary_link{
        .c-nav_primary_plus{
          &::after{
            transform: scaleY(0);
          }
        }
      }
    }

    &::before{
      content: '';
      width: 2px;
      height: calc(100% + 8.5rem);
      position: absolute;
      top: -4.5rem;
      left: 0;
      background-color: rgba($grey, 0.15);
    }
  }

  @media(max-width: $from-medium){
    .c-nav_bg{
      display: none;
    }

    .c-nav_wrap{
      width: 100% !important;
    }
  }
}

.has-navOpen {
  .c-nav {
    //visibility: visible;
    transform: translateY(0);
    .c-nav_primary {
      opacity: 1;
      transform: translateX(0);
    }
    .c-nav_shop {
      opacity: 1;
      transform: translateY(0);
    }
    .c-nav_image-bg {
      transform: translateY(0);
    }
  }
}
