.c-text{
    font-family: 'Hind';
    font-weight: $normal;
    font-size: $font-size;
    line-height: 1.2rem;

    &.-font-larger{
        font-size: $font-size-h6;
    }

    li{
        display: flex;
        align-items: flex-start;
        &::before{
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $second;
            padding-right: 10px;
            margin-right: 10px;
            margin-top: calc(0.6rem - 5px);
        }
    }

    h1 {
        font-size: rem($font-size-h1);
        font-weight: $bold;
        line-height: $line-height-default;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h1)} - 30px);
        }
        @media (max-width: $to-tiny){
          font-size: 2.5rem;
        }
    }

    h2 {
        font-size: rem($font-size-h2);
        font-weight: $semibold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 15px);
        }
    }

    h3 {
        font-size: rem($font-size-h3);
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h3)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 15px);
        }
    }

    h4 {
        font-size: rem($font-size-h4);
        font-weight: $semibold;
    }

    h5 {
        font-size: rem($font-size-h5);
        font-weight: $semibold;
    }

    h6 {
      font-size: rem($font-size-h6);
      font-weight: $semibold;
    }
}
