.c-roundBtn_container{
    .c-roundBtn{
        height: 3rem;
        width: 3rem;
        min-width: 3rem;
        background: $primary;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .c-roundBtn_span{
            z-index: 1;
            display: flex;
            svg{
                path, line{
                    fill: $red;
                    stroke: $red;
                }
            }

            &.-rotate{
                svg{
                    transform-origin: center;
                    transform: rotate(90deg);
                }
            }
        }

        &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            transform: scale(0);
            transform-origin: center;
            background: $red;
            transition: 0.3s ease-out;
            z-index: 0;
            border-radius: 50%;
        }
    }

    &.-red{
        .c-roundBtn{
            background: $red;

            .c-roundBtn_span{
                svg{
                    path, line{
                        fill: white;
                        stroke: white;
                    }
                }
            }

            &::after{
                background: $white;
            }
        }

        &:hover{
            .c-roundBtn{
                .c-roundBtn_span{
                    svg{
                        path, line{
                            fill: $red;
                            stroke: $red;
                        }
                    }
                }
            }
        }

        &.-no-anim{
            .c-roundBtn{
                background: $red;
    
                .c-roundBtn_span{
                    svg{
                        path, line{
                            fill: white;
                            stroke: white;
                        }
                    }
                }
    
                &::after{
                    background: $red;
                }
            }
            &:hover{
                .c-roundBtn{
                    .c-roundBtn_span{
                        svg{
                            path, line{
                                fill: white;
                                stroke: white;
                            }
                        }
                    }
                }
            }
        }
    }

    &:hover{
        .c-roundBtn{
            &::after{
                transform: scale(100%);
            }
            .c-roundBtn_span{
                svg{
                    path, line{
                        fill: $primary;
                        stroke: $primary;
                    }
                }
            }
        }
    }

    &.-cursor{
        cursor: pointer;
    }
}

