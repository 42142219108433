.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    .c-card_image {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      .c-figure {
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      img {
        transition: $speed $easing;
      }
    }
    .c-card_wrap {
     
    }
    &:hover {
      .c-card_image {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &.-actualite {
    .c-card_image{
      height: 264px;

      .c-figure{
        background-color: lightgray;
      }
    }
    figure{
      position: relative;
      overflow: hidden;
      border-radius: 2rem;

      img{
        transition: 0.3s ease-out;
      }
    }

    figcaption{
      position: absolute;
      padding: 0.8rem 2rem;
      bottom: 0;
      left: 0;
      font-weight: $semibold;
      border-bottom-left-radius: 1rem;
    }

    .c-card_body{
      div{
        transition: 0.3s ease-out;
      }
    }

    &:hover{
      figure{
        img{
          transform: scale(1.05);
        }
      }

      .c-card_body{
        div{
          color: $primary;
        }
      }
    }

    .c-card_content{
      display: grid;
      grid-template-columns: 1fr 3rem;
      grid-gap: 1rem;
      align-items: center;
    }
  }

  &.-projets{
    border: 2px solid $grey;
    padding: 1.5rem;
    border-radius: 1.5rem;
    transition: 0.3s ease-out;
    min-height: 220px;
    position: relative;
    .c-card_body{
      .u-c-grey{
        transition: 0.3s ease-out;
      }
    }
    
    .c-card_foot{
      position: absolute;
      bottom: 1.5rem;
    }

    &:hover{
      .c-card_body{
          .u-c-grey{
            color: $primary;
          }
      }
      border: 2px solid $primary;
    }

    @media (max-width: $from-small){
      min-height: 170px;
    }
  }

  &.-equipe{
    padding: 2rem;
    border-radius: 2rem;
    border: 3px solid $grey;
  }
}
