.b-slider {
    .o-ratio_content_bg {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: transparent linear-gradient(180deg, #009CB5B4 0%, #009CB500 100%) 0% 0% no-repeat padding-box;
            mix-blend-mode: multiply;
        }
    }
    .c-slider_slide {
        height: auto!important;
    }
    .c-slider_content {
        position: relative;
        z-index: 2;
    }
}

.c-slider_container {
      &.-offset-small {
        margin-right: rem(-$unit-small);
      }
      &.-offset {
        margin-right: rem(-$unit);
      }
    
      &.-offset-double {
        margin-right: calc(#{rem(-$unit)} * 2);
      }
  
    .swiper-button-disabled {
      .c-button {
        opacity: 0.5;
        cursor: default;
      }
    }
   
    .swiper-slide {
      height: auto!important;
    }
}