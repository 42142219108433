// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$primary: #FFCA6E;
$second: #ADD6FF;
$black: #606060;
$red: #F27070;
$green: green;
$grey: #606060;
$grey-light: #707070;
$grey-lighter: rgba($grey, 0.3);
$grey-footer: #2F343B;
$white-footer: #ECF0F5;
$white-footer2 : darken($white-footer, 0.5);
$black-overlay-light: rgba(0, 0, 0, 0.7);
$blue-overlay-light: rgba($second, 0.9);


// Specific
// =============================================================================
// Link
$link-color:       $grey;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: #FFFFFF;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
