.b-intro{
    &.-accueil{
        position: relative;
        height: calc(100vh - 86px);
        @media (max-width: $to-medium) {
            height: auto;
        }
    }

    .b-intro_imgContainer{
        position: relative;
        min-height: 320px;
        &::after{
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $second;
            opacity: 0.2;
        }
        .b-intro_title{
            position: absolute;
            top: 2rem;
            z-index: 1;
        }
        figure{
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                min-height: 320px;
                object-fit: cover;
            }
        }
    }

    .b-intro_btnContainer{
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;

        span{
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
        }
    }

    .b-intro_link{
        cursor: pointer;
        &:not(:last-of-type){
            border-bottom: 2px solid $grey-light;
        }

        .b-intro_linkComponent{
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 50%;
            overflow: hidden;
            position: relative;

            span{
                z-index: 1;
                svg{
                    path, line{
                        fill: $red;
                        stroke: $red;
                    }
                }
            }
            &::after{
                content: '';
                width: 100%;
                height: 100%;
                transition: 0.3s ease-out;
                transform: scale(0%);
                background-color: $red;
                border-radius: 50%;
                transform-origin: center;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
            }
        }

        &:hover{
            p{
                transition: 0.3s ease-out;
                color: $primary;
            }

            .b-intro_linkComponent{
                &::after{
                    transform: scale(100%);
                }

                span{
                    svg{
                        path, line{
                            fill: $primary;
                            stroke: $primary;
                        }
                    }
                }
            }
        }

        &.-search{
            border-bottom: 2px solid rgba($color: $grey, $alpha: 0.5);
            text-transform: uppercase;
        }

        &.-rapide{
            border-bottom: inherit;
            min-width: max-content;
            justify-content: space-between;

            span{
                padding-left: inherit;
            }
        }
    }

    @media(max-width: $from-midsmall){
        flex-direction: column;
    }
}
