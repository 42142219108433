.b-timeline{
    .b-timeline_item{
        margin-bottom: 2rem;

        .b-timeline_title{
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-out;
            cursor: pointer;
            width: 100%;

            &:hover{
                color: $red;
            }

            &::before{
                content: '';
                width: 100%;
                height: 2px;
                margin-right: 2rem;
                background: $grey;
                transform: scaleX(0);
                transform-origin: right;
                transition: 0.3s ease-out;
            }

            &::after{
                content: '';
                width: 100%;
                height: 2px;
                margin-left: 2rem;
                background: $grey;
                transform: scaleX(0);
                transform-origin: left;
                transition: 0.3s ease-out;
            }
        }
        
        .b-timeline_content{
            transform: translateY(10rem);
            visibility: hidden;
            transition: 0.3s ease-out;
            height: 0;
            opacity: 0;

            figure{
                height: 350px;
            }
        }

        &.t-clicked{
            .b-timeline_title{
                color: $red;

                &::after, &::before{
                    transform: scaleX(100%);
                }
            }

            .b-timeline_content{
                transform: translateY(0rem);
                visibility: visible;
                height: 100%;
                opacity: 1;
            }
        }
    }
}
