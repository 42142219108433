.b-etapes {
    .b-etapes_item_head{
        span{
            display: flex;
            align-items: center;
            position: relative;
            &::after{
                content: '';
                width: 100%;
                height: 2px;
                background: $grey;
                margin-left: 2rem;
            }
        }
    }
}